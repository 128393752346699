<template>
  <vs-row justify="center" class="container">
    <vs-col w="8" sm="10">
      <vs-row justify="center">
        <vs-col w="2" sm="4" class="iconPlanPart">
          <slot name="icon">
            <VueIcon />
          </slot>
        </vs-col>
        <vs-col offset="1" w="9" sm="12" class="textPlanPart">
          <slot name="text">
            <h2>Les bases du framework</h2>
            <h3>Date de sortie estimée: 8 février</h3>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cupiditate ex aperiam veniam, repellat minus quia atque laborum quibusdam facilis perferendis dolorem eligendi dignissimos id, deserunt nulla perspiciatis quis eveniet velit.</p>
          </slot>
        </vs-col>
      </vs-row>
    </vs-col>
  </vs-row>
</template>

<script>
import VueIcon from './icons/Vue.vue'
export default {
  name: "PlanPart",
  components: { VueIcon }
}
</script>

<style scoped>
.container {
  margin: 100px 0 0;
}
</style>

<style lang="scss">
.iconPlanPart {
  svg { width: 100%; }
}
.textPlanPart {
  text-align: left;
  h2 {
    margin: 0;
    font-size: 2em;
  }
  h3 {
    opacity: 0.75;
    font-size: 1.25em;
    color: rgb(var(--vs-primary));
    margin: 10px 0 20px;
  }
  p {
    font-size: 17px;
    line-height: 1.5;
    text-align: justify;
  }
}
@media (max-width: 900px) {
  .textPlanPart { margin-left: 0 !important }
  .iconPlanPart { margin-bottom: 30px; }
}
</style>