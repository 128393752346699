<template>
  <vs-row justify="center">
    <vs-col w="12" class="accordionContainer">
      <slot></slot>
    </vs-col>
  </vs-row>
</template>

<script>
import '../../public/accordion/gianniAccordion.min.js'
export default {
  name: 'Accordion',
  data() {
    return {
      accordion: null
    }
  },
  mounted() {
    this.accordion = new gianniAccordion({
      elements: ".accordionContainer .element",
      trigger: "[data-accordion-element-trigger]",
      content: "[data-accordion-element-content]",
    });
  }
};
</script>

<style lang="scss">

.accordionContainer {

  box-sizing: border-box; 
  margin: 50px auto;
  border-radius: 2px;
  background: transparent;
  box-shadow: 0 0 10px 0 rgba(var(--vs-dark), 0.1);
 
  .element {
    box-sizing: border-box;
    position: relative;
    font-family: 'Roboto', sans-serif;
    transition: all .3s;
    background: transparent;
    
    &.selected {
      transform: scale(1.1);
      box-shadow: 0 0 10px 0 rgba(var(--vs-dark), 0.1);
      z-index: 2;
    }
  }
    
  h4 {
    box-sizing: border-box;
    margin: 0;
    position: relative;
    background-color: rgb(var(--vs-background));
    padding: 20px 75px 20px 30px;
    font-size: 20px;
    line-height: 30px;
    color: rgb(var(--vs-dark));
    overflow: hidden;
    
    &:hover {
      cursor: pointer;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -1px;
      margin-right: 30px;
      width: 20px;
      height: 2px;
      background: rgb(var(--vs-dark));
      transition: transform .3s;
    }

    &:before {
      transform: rotate(0deg);
      animation: close-line-one-in .8s forwards;
    }
    
    &:after {
      transform: rotate(90deg);
      animation: close-line-two-in .8s forwards;
    }    

  }
  .selected h4:before {
    animation: close-line-one-out .8s forwards;
  }  
  .selected h4:after {
    animation: close-line-two-out .8s forwards;  
  }

  .content {  
    box-sizing: border-box;
    opacity: 0;
    background-color: rgb(var(--vs-background-contrast));
    transform: translateY(20px);
    transition: all ease-out .4s .1s;
  }  
    
  .selected .content {
    transform: translateY(0);
    opacity: 1;
  }
  
  .collapsed .content {
    display: none;
  }

  p {
    margin: 0;
    box-sizing: border-box;
    padding: 25px 30px 40px;
    font-size: 1em;
    line-height: 1.75;
    color: rgb(var(--vs-dark));
  }

}


@keyframes close-line-one-out {
    0%, 50% {
      transform: translateX(0);
    }
    75% { 
      transform: translateX(50px);
    }
    76% { 
      transform: translateX(50px) translateY(50px);
    }
    100% { 
      transform: rotate(45deg);
    }
}

@keyframes close-line-one-in {
    0%, 20% {
      transform: rotate(45deg);
    }
    60% { 
      transform: translateX(50px) translateY(50px) rotate(45deg);
    }
    61% { 
      transform: translateX(50px);
    }
    100% { 
      transform: rotate(0deg);
    }
}

@keyframes close-line-two-out {
    0%, 50% { 
      transform: rotate(90deg);
    }
    75% { 
      transform: translateY(-50px) rotate(90deg);
    }
    76% {
      transform: translateY(-50px) translateX(50px) rotate(90deg);
    }
    100% { 
      transform: rotate(135deg);
    }
}

@keyframes close-line-two-in {
    0% { 
      transform: rotate(135deg);
    }
    50% { 
      transform: translateY(-50px) translateX(50px) rotate(135deg);
    }
    51% { 
      transform: translateY(-50px) rotate(90deg);
    }
    100% { 
      transform: rotate(90deg);
    }
}
</style>
