<template>
	<!-- Logo non-officiel créé par ergenekonyigit disponible ici: https://github.com/vuejs/vue/issues/6305 -->
	<svg id="svg" version="1.1"	xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0, 0, 400,400">
		<g id="svgg">
			<path id="path0" d="M50.011 50.150 C 50.017 50.233,66.870 83.983,87.461 125.150 L 124.900 200.000 147.450 154.900 L 170.000 109.800 155.050 79.900 L 140.101 50.000 95.050 50.000 C 70.273 50.000,50.005 50.068,50.011 50.150 M244.950 79.900 L 230.000 109.800 252.550 154.900 L 275.100 200.000 312.539 125.150 C 333.130 83.983,349.983 50.233,349.989 50.150 C 349.995 50.068,329.727 50.000,304.950 50.000 L 259.899 50.000 244.950 79.900 " stroke="none" fill="#344c5c" fill-rule="evenodd"></path>
			<path id="path1" d="M147.600 155.026 C 135.335 179.570,125.194 199.762,125.064 199.897 C 124.842 200.129,50.000 349.687,50.000 349.900 C 50.000 349.955,70.273 350.000,95.051 350.000 L 140.101 350.000 155.051 320.000 C 163.273 303.500,176.727 276.500,184.949 260.000 C 193.172 243.500,199.944 230.000,200.000 230.000 C 200.056 230.000,206.828 243.500,215.051 260.000 C 223.273 276.500,236.727 303.500,244.949 320.000 L 259.899 350.000 304.949 350.000 C 329.727 350.000,350.000 349.955,350.000 349.900 C 350.000 349.687,275.158 200.129,274.936 199.897 C 274.806 199.762,264.665 179.570,252.400 155.026 C 240.136 130.482,230.055 110.400,230.000 110.400 C 229.944 110.400,223.194 123.810,215.000 140.200 C 206.806 156.590,200.056 170.000,200.000 170.000 C 199.944 170.000,193.194 156.590,185.000 140.200 C 176.806 123.810,170.056 110.400,170.000 110.400 C 169.945 110.400,159.864 130.482,147.600 155.026 " stroke="none" fill="#4cc48c" fill-rule="evenodd"></path>
			<path id="path2" d="" stroke="none" fill="#4ca484" fill-rule="evenodd"></path>
			<path id="path3" d="" stroke="none" fill="#4cac84" fill-rule="evenodd"></path>
			<path id="path4" d="" stroke="none" fill="#3c646c" fill-rule="evenodd"></path>
		</g>
	</svg>
</template>

<script>
export default {
  name: "Vuex"
}
</script>