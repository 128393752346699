<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 710.846 679.615">
    <path id="Tracé_9" data-name="Tracé 9" d="M140.384,470.692,283.461,215H0Z" transform="translate(0 -104)" fill="#41b883"/>
    <path id="Tracé_10" data-name="Tracé 10" d="M0,0H327.846L-57.077,679.615l-165.23-289.846Z" transform="translate(383)" fill="#41b883"/>
    <path id="Tracé_11" data-name="Tracé 11" d="M191.371,214.737,383.846,554.808l-385-.242Z" transform="translate(327 124.808)" fill="#35495e"/>
  </svg>
</template>

<script>
export default {
  name: "Vuelidate"
}
</script>