<template>
  <vs-col w="12">
    <vs-card type="3" class="card">
      <template #title>
        <p class="sentence" v-for="sentence in sentences" :key="sentence" v-html="sentence"></p>
      </template>
      <template #text>
        <p>
          <strong>{{ author }}</strong>, avis rédigé après avoir suivi {{ completionPercent }}% de la formation
        </p>
      </template>
    </vs-card>
  </vs-col>
</template>

<script>
  export default {
    props: {
      sentences: {
        type: Array,
        required: true
      },
      author: {
        type: String,
        required: true
      },
      completionPercent: {
        type: Number,
        default: 100
      } 
    }
  }
</script>

<style lang="scss" scoped>
  .card {
    width: 100%;
    box-sizing: border-box;
    margin: 15px auto;
    padding: 0 15px;
    p { color: rgb(var(--vs-dark)) !important }
  }
  .sentence {
    position: relative;
    margin: 20px;
    font-size: 1.1rem;
    line-height: 1.5;
    opacity: 1;
    &::before { 
      content: "“";
      position: absolute;
      top: -20px;
      left: -20px;
      color: rgba(var(--vs-dark), 25%);
      font-size: 2.5rem;
    }
    &::after {
      content: " ”";
      color: rgba(var(--vs-dark), 25%);
    }
  }
</style>

<style>
  .vs-card {
    padding: 0 15px 15px;
    max-width: none !important;
    /* box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5) !important; */
    box-shadow: none !important;
    border: solid 1px rgba(var(--vs-dark), 25%);
    cursor: default !important;
  }
  .vs-card__title {
    padding-top: 0 !important;
  }
</style>