<template>
  <svg id="Composant_1_1" data-name="Composant 1 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 220.8">
    <g id="vue">
      <path id="Tracé_1" data-name="Tracé 1" d="M204.8,0H256L128,220.8,0,0H97.92L128,51.2,157.44,0Z" fill="#41b883"/>
      <path id="Tracé_2" data-name="Tracé 2" d="M0,0,128,220.8,256,0H204.8L128,132.48,50.56,0Z" fill="#41b883"/>
      <path id="Tracé_3" data-name="Tracé 3" d="M50.56,0,128,133.12,204.8,0H157.44L128,51.2,97.92,0Z" fill="#35495e"/>
    </g>
    <line id="Ligne_1" data-name="Ligne 1" y1="68" transform="translate(226.5 152.5)" fill="none" stroke="#41b883" stroke-width="25"/>
    <g id="Polygone_1" data-name="Polygone 1" transform="translate(197 101)" fill="#41b883">
      <path d="M 58.1414909362793 51.5 L 0.8585100173950195 51.5 L 29.5 1.013307690620422 L 58.1414909362793 51.5 Z" stroke="none"/>
      <path d="M 29.5 2.026603698730469 L 1.717014312744141 51 L 57.28298568725586 51 L 29.5 2.026603698730469 M 29.5 3.814697265625e-06 L 59 52 L 0 52 L 29.5 3.814697265625e-06 Z" stroke="none" fill="#41b883"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "AdvancedVue"
}
</script>