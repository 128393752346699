<script>
import { setExperiment, getPickedVariant } from 'split-testing';

import Badge from '../Badge.vue'
import BaseSwitch from '../BaseSwitch.vue'
import FormCPF from '../FormCPF.vue'
import CheckIcon from '../icons/Check.vue'
import MonCompteFormationIcon from '../icons/MonCompteFormation.vue'

export default {
  components: { 
    Badge, 
    BaseSwitch,
    CheckIcon,
    MonCompteFormationIcon,
    FormCPF
  },
  data() {
    return {
      isInClub: (window.location.pathname === '/club'),
      id: new URLSearchParams(window.location.search).get('id') || null,
      coupon: new URLSearchParams(window.location.search).get('coupon') || null,
      affiliate: new URLSearchParams(window.location.search).get('affiliate') || null,
      teamVersion: false,
      numberOfAccess: 5,
      prices : {
        pro: {
          raw: 97,
          forClub: 87,
          byPart: 32,
          byPartForClub: 29,
          nbOfParts: 3
        },
        complete: {
          raw: 77,
          forClub: 69,
          byPart: 26,
          byPartForClub: 23,
          nbOfParts: 3
        }
      },
      discountThreshold: [3, 5],
      discountValue: [5, 10],
      clubModalState: false,
      cpfModalState: false
    }
  },
  computed: {
    teamPrice() {
      let percent = 0
      for (let i = 0; i < this.discountThreshold.length; i++) {
        const threshold = this.discountThreshold[i]
        if (this.numberOfAccess >= threshold) {
          percent = this.discountValue[i]
        } else {           
          break;
        }
      }
      return Math.round(this.prices.pro.raw * this.numberOfAccess * ((100 - percent) / 100))
    },
    couponParam() {
      // if (this.affiliate == 'johntaieb') {
      //   return 'coupon=JOHNTAIEBVUEJS'
      // }
      if (this.coupon) {
        return 'coupon=' + this.coupon
      } else {
        return 'coupon=nocoupon'
      }
    },
    completePlanUrl() {
      return 'https://buy.stripe.com/6oEfZfg8DbOZ68o28g'
    },
    proPlanUrl() {
      if (this.teamVersion) {
        return 'https://devtheory.thrivecart.com/le-framework-vue-pro-teams/?quantity=' + this.numberOfAccess
        // if (this.affiliate == 'johntaieb') {
        //   return 'https://johntaieb--devtheory.thrivecart.com/le-framework-vue-pro-teams/60b9e5ceefa50/?quantity=' + this.numberOfAccess
        // } else {
        //   return 'https://devtheory.thrivecart.com/le-framework-vue-pro-teams/?quantity=' + this.numberOfAccess
        // }
      } else {
        return 'https://buy.stripe.com/14k5kB9Kf9GR0O44gl'
      }
    }
  },
  methods: {
    sendEventChekckoutPageClicked(courseVersion) {
      plausible('Clic sur une page d\'achat', { 
        props: {
          courseVersionClicked: courseVersion
        }              
      })
    },
    sendEventCpfCompleted() {
      plausible('Demande de CPF envoyé')
    }
  },
  created() {
    // Gestion des affiliés
    if (this.affiliate == 'johntaieb') {
      this.$vs.notification({
        sticky: true,
        duration: 'none',
        color: 'danger',
        position: 'top-right',
        title: 'Promotion terminée',
        text: 'La formation est toujours disponible mais la promotion de -25% grâce à John Taieb est terminée.'
      })
    } else if (this.affiliate == 'mikecodeur') {
      this.$vs.notification({
        sticky: true,
        duration: 'none',
        color: 'danger',
        position: 'top-right',
        title: 'Promotion terminée',
        text: 'La formation est toujours disponible mais la promotion de -25% grâce à Mike Codeur est terminée.'
      })
    }    
  }
}
</script>

<template>
  <section id="pricing">

    <h1>Créer des applications de A à Z avec Vue.js 3</h1>
    <h3>Choisissez votre plan favori</h3>
    <!-- <h2 class="subTitle" v-if="affiliate === 'johntaieb'">Réduction de -25% grâce à John Taieb</h2> -->

    <vs-row justify="center" align="center">

      <vs-col lg="5" sm="8" xs="8" class="containerOnePrice">
        <vs-row class="topPart">

          <h2>Complet</h2>

          <strong class="price">

            <template v-if="isInClub">
              <strike>{{ prices.complete.raw }}</strike> {{ prices.complete.forClub }}
            </template>

            <template v-else>{{ prices.complete.raw }}</template>

            <sup>EUR</sup>

          </strong>

        </vs-row >
        <vs-row  class="bottomPart">
          <ul>
            <li>
              <CheckIcon class="icon"/> Formation Vue.js 3 (<em>accès à vie</em>)
              <ul>
                <li>1) Les bases de Vue.js 3</li>
                <li>2) Les outils à utiliser au quotidien</li>
                <li>3) Le routing via VueRouter</li>
                <li>4) Le state via Vuex</li>
                <li>5) Le développement avancé</li>
              </ul>
            </li>
            <li>
              <CheckIcon class="icon"/> Accès au code du projet de A à Z
            </li>
            <li>
              <CheckIcon class="icon"/> Espace pour toutes vos questions
            </li> 
          </ul>
          <vs-button :href="completePlanUrl" @click="sendEventChekckoutPageClicked('complete')" blank size="large" border class="btnBuy">
            Commander la formation
          </vs-button>
        </vs-row >
      </vs-col>

      <vs-col lg="7" sm="9" xs="9" class="containerOnePrice moreImportant">
        <vs-row class="topPart">

          <h2>Professionnel</h2>

          <!-- Version pour individu -->
          <template v-if="!teamVersion">

            <strong class="price">

              <template v-if="isInClub">
                <strike>{{ prices.pro.raw }}</strike> {{ prices.pro.forClub }}
              </template>

              <template v-else>{{ prices.pro.raw }}</template>

              <sup>EUR</sup>  


            </strong>

          </template>

          <!-- Version pour équipes -->
          <template v-else>

            <strong class="price">
              <strike v-if="prices.pro.raw * numberOfAccess > teamPrice">{{ prices.pro.raw * numberOfAccess }}</strike>
              {{ teamPrice }} <sup>EUR</sup>
            </strong>  

            <div class="containerInputAccess">
              <label for="numberOfAccess">Nombre d'accès</label>
              <input v-model="numberOfAccess" type="number" id="numberOfAccess" min="1" />
            </div>

          </template>

          <BaseSwitch v-model="teamVersion">          
            <template v-slot:beforeText>
              Individu
            </template>
              Équipe
          </BaseSwitch>

        </vs-row >
        <vs-row  class="bottomPart">
          <ul>
            <li>
              <CheckIcon class="icon"/> Formation Vue.js 3 (<em>accès à vie</em>)
              <ul>
                <li>1) Les bases de Vue.js 3</li>
                <li>2) Les outils à utiliser au quotidien</li>
                <li>3) Le routing via VueRouter</li>
                <li>4) Le state via Vuex</li>
                <li>5) Le développement avancé</li>
              </ul>
            </li>
            <li>
              <CheckIcon class="icon"/> Accès au code du projet de A à Z
            </li>
            <li>
              <CheckIcon class="icon"/> Espace pour toutes vos questions
            </li>
            <li>
              <CheckIcon class="icon"/> Équivalent aux cours présentiels de 1900€
            </li>        
            <li>
              <CheckIcon class="icon"/> Sections-bonus (<em>+ 3 heures</em>)
              <ul>
                <li>1) Gestion complète des formulaires</li>
                <li>2) Système d'utilisateurs via Firebase</li>
              </ul>
            </li>
            <li>
              <CheckIcon class="icon"/> Accès à vie aux futures sections-bonus
            </li> 
          </ul>
          <vs-button :href="proPlanUrl" @click="sendEventChekckoutPageClicked(teamVersion ? 'professional team' : 'professional')" blank size="large" class="btnBuy">
            {{ teamVersion ? "Ajouter Vue.js à votre entreprise" : "Commander la formation PRO" }}
          </vs-button>          
          <!-- <span v-if="!teamVersion" @click.prevent="cpfModalState = true" class="specialCPF">
            Ou faire financer via<MonCompteFormationIcon style="width: 75px; padding-left: 15px;"/>
          </span> -->
        </vs-row>

        <!-- Formulaire pour les CPFs -->
        <!-- <vs-dialog width="550px" not-center v-model="cpfModalState" class="modal">
          <div>
            <p style="text-align: center">
              <MonCompteFormationIcon />
            </p>
            <p>
              Cette formation est finançable via CPF seulement en la couplant avec la certification DiGITT, c'est pourquoi son prix passe de {{ prices.pro.raw }}€ à 399€ en passant par <a href="https://www.moncompteformation.gouv.fr/espace-prive/html/#/" target="_blank">Mon Compte Formation</a>.
            </p>
            <p>
              Une fois le financement confirmé, vous aurez accès à la formation en intégralité. Afin d'avoir un <strong>accès à vie</strong> vous devrez seulement suivre au moins 6h de la formation le 1er mois (c'est une réglementation des CPFs).
            </p>
            <p>
              Veuillez compléter ces quelques informations pour que le financement puisse se lancer automatiquement (le chargement peut durer une dizaine de secondes) : 
            </p>
            <FormCPF @submitAndConfirmed="sendEventCpfCompleted"/>
          </div>
        </vs-dialog> -->

        <!-- Informations pour les membres du Club JS Master -->
        <template v-if="!isInClub">
          <span class="littleSentenceBelowPlan" @click="clubModalState = true">
            Vous faites partie du Club JS Master ? Cliquez-ici
          </span>
          <vs-dialog width="550px" not-center v-model="clubModalState" class="modal">
            <div>
              <p>
                Pour profiter de la réduction de -10% sur les plans individiels: allez sur le channel #annonces du serveur Discord dédié au Club et vous y trouverez le lien unique en question 👍
              </p>
              <p>
                Sinon, contactez-moi à tout moment avec les moyens qui se trouvent en bas de cette page ;)
              </p>
            </div>
            <template #footer>
              <div>
                <vs-button @click="clubModalState = false" transparent>
                  Ok
                </vs-button>
              </div>
            </template>
          </vs-dialog>
        </template>     

      </vs-col>

    </vs-row>

  </section>
</template>

<style scoped lang="scss">

#pricing { 
  padding: 60px 0;
  max-width: 900px;
  text-align: center;
}

h1 {
  font-size: 2em;
  margin: 0 auto;
}

h2 {
  width: 100%;
  margin: 0 auto 20px;
}

h3 {
  width: 100%;
  font-size: 1.3em;
  opacity: 0.5;
  margin: 20px auto 40px;
}

.subTitle {
  font-size: 1.5em;
  margin: 40px auto;
  opacity: 0.75;
  font-style: italic
}

.containerOnePrice {

  position: relative;
  box-shadow: 0 0 10px 0 rgba(var(--vs-dark), 0.1);
  border-radius: 10px;
  &.moreImportant {
    border: 3px solid rgb(var(--vs-primary));
  }

  .topPart {
    padding: 10%;
    background-color: rgb(var(--vs-background-contrast));
    text-align: center;
    border-radius: 10px 10px 0 0;
  }

  .bottomPart {
    padding: 5% 7.5%;
    background-color: rgb(var(--vs-background));
    border-radius: 0 0 10px 10px;
  }

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    .topPart { border-top-right-radius: 0; }
    .bottomPart { border-bottom-right-radius: 0; }
  }

}

.littleSentenceBelowPlan {
  display: inline-block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.5;
  line-height: 30px;
  font-size: 14px;
  font-style: italic;
  cursor: pointer;
  &:hover { 
    text-decoration: underline;
  }
}

.modal p {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: rgb(var(--vs-dark));
  line-height: 1.75;
  padding: 2% 2% 0;
}

sup { font-size: 0.5em; }

strike { 
  opacity: 0.5;
  font-size: 0.85em;
  vertical-align: 5px;
  font-weight: normal;
}

.price {
  display: block;
  width: 100%;
  font-size: 3em;
}

.smallPrice {
  display: block;
  width: 100%;
  margin-top: 10px;
  padding-bottom: 21.2px;
  font-size: 1em;
  opacity: 0.75;
}

.containerInputAccess {
  display: block;
  width: 100%;
  margin-top: 10px;
  font-size: 1em;
}

ul {
  width: 100%;
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
  li {
    margin: 20px 0;
    line-height: 1.75;
  }

  ul {
    padding-left: 50px;
    box-sizing: border-box;
    li {
      margin: 10px 0;
      line-height: 1.5;
      opacity: 0.75;
    }
  }

}

.icon {  
  display: inline-block;
  width: 25px;
  margin-right: 5px;
  vertical-align: middle;
  stroke: rgb(var(--vs-primary))
}

#numberOfAccess {
  width: 50px;
  padding: 7px;
  background-color: rgb(var(--vs-background-contrast));
  border: solid 1px rgb(var(--vs-primary)) !important;
  border-radius: 5px;
  font-size: 20px;
  color: #fff;
}

.btnBuy {
  display: block;
  margin: 20px auto;
  padding: 7px 15px;
}

.specialCPF {
  display: inline-block;  
  margin: -10px auto 25px;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  &:hover { text-decoration: underline; }
  svg {
    vertical-align: middle;
  }
}

@media screen and (max-width: 900px) {
  .containerOnePrice:first-child {
    margin-bottom: 25px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    .topPart { border-top-right-radius: 10px; }
    .bottomPart { border-bottom-right-radius: 10px; }
  }
}

</style>

<style>
  .modal .vs-dialog__close {
    --vs-color: var(--vs-dark)
  }
</style>