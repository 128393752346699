<template>  
  <svg  fill="currentColor" width="140" height="66" id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 524.75 263.67">
    <path class="cls-1" d="M524.75,162.27V81.15H281.48V0H0V87.75H98.33v74.52H0v101.4H524.75V162.27Zm-44.11-43.41v11.61H459.55v9.8H487v12.4H446.28V97.37h40.21v12.4H459.55v9.09Zm-90.55-9.09V97.37h46.69v12.4H420v42.9H406.68v-42.9ZM382,116.33c0,11.85-8.21,19-21.8,19h-7.82v17.38H339.12V97.37h21.09C373.8,97.37,382,104.48,382,116.33Zm-71,3.24H311c-.08,0-4.51,7.58-5,8.37L299.49,139h-6.87l-6.48-11.06c-.47-.79-4.9-8.37-5-8.37h-.08c-.08,0,.39,6.48.39,19.59v13.51h-13V97.37h12.48l10.35,16.75c4,6.56,4.66,8,4.74,8h.08c.07,0,.71-1.42,4.74-8l10.34-16.75h12.48v55.3h-13V139.16C310.63,126.05,311.1,119.57,311,119.57ZM256.25,125c0,16.67-12.87,28.75-28.75,28.75S198.82,141.69,198.82,125s12.8-28.75,28.68-28.75S256.25,108.35,256.25,125ZM165.1,140.5c5.29,0,10.35-2.68,12.64-6.47l13.43,3.47a28.4,28.4,0,0,1-26.07,16.27c-15.88,0-28.68-12.08-28.68-28.75s12.8-28.75,28.68-28.75A28.45,28.45,0,0,1,190.22,111l-13.51,3.55c-2.53-3.16-7.27-5.13-11.61-5.13-9.16,0-14.85,6.56-14.85,15.64C150.25,134.58,156.49,140.5,165.1,140.5Zm33.14-81.22V71.52H185V16.23h12.48L209.22,32c3.55,4.74,12,16.66,12.08,16.66h.08c.08,0-.08-9.79-.08-19.82V16.23h13.28V71.52H222.09L210.8,56.12c-5.06-6.95-12.48-17.46-12.56-17.46h-.08C198.08,38.66,198.24,47.19,198.24,59.28ZM169.46,43.87c0,16.67-12.88,28.76-28.76,28.76S112,60.54,112,43.87A28.2,28.2,0,0,1,140.7,15.12C156.58,15.12,169.46,27.21,169.46,43.87ZM42.9,16.23H55.39L65.74,33c4,6.56,4.66,8,4.73,8h.08c.08,0,.71-1.42,4.74-8L85.64,16.23H98.12V71.52h-13V58c0-13.11.47-19.59.39-19.59H85.4c-.07,0-4.5,7.59-5,8.38L74,57.86H67.08L60.6,46.8c-.47-.79-4.9-8.38-5-8.38h-.08c-.07,0,.4,6.48.4,19.59V71.52h-13ZM518.14,257.05H6.61V168.88H518.14Z"/>
    <polygon class="cls-1" points="24.5 240.62 37.77 240.62 37.77 220.47 58.07 220.47 58.07 208.54 37.77 208.54 37.77 197.72 63.92 197.72 63.92 185.32 24.5 185.32 24.5 240.62"/>
    <path class="cls-1" d="M68.78,213a28.2,28.2,0,0,0,28.67,28.75c15.88,0,28.76-12.09,28.76-28.75s-12.88-28.76-28.76-28.76S68.78,196.3,68.78,213Zm43.6,0c0,9.24-5.92,15.64-14.93,15.64S82.6,222.21,82.6,213s5.93-15.64,14.85-15.64S112.38,203.65,112.38,213Z"/>
    <path class="cls-1" d="M149.7,222.6h6.79l10.27,18h14.85l-12.24-20.39c6.39-3,9.95-8.53,9.95-15.95,0-11.38-8.37-19-22.28-19H136.42v55.3H149.7Zm0-24.88h9.16a6.56,6.56,0,1,1,0,13.11H149.7Z"/>
    <path class="cls-1" d="M203.87,227.11c0-13.12-.47-19.59-.39-19.59h.08c.08,0,4.5,7.58,5,8.37L215,227h6.87l6.48-11.06c.48-.79,4.9-8.37,5-8.37h.08c.08,0-.4,6.47-.4,19.59v13.51h13v-55.3H233.58l-10.35,16.75c-4,6.55-4.66,8-4.74,8h-.08c-.08,0-.71-1.43-4.74-8l-10.35-16.75H190.84v55.3h13Z"/>
    <path class="cls-1" d="M270.57,231.93h21.88l3.16,8.69H309.9l-22.11-55.3H275.23l-22.12,55.3h14.3Zm8.21-22.36c1.19-3.16,2.61-8.14,2.69-8.14h.08c.08,0,1.5,5,2.68,8.14l3.95,10.74H274.83Z"/>
    <polygon class="cls-1" points="322.1 240.62 335.38 240.62 335.38 197.72 352.2 197.72 352.2 185.32 305.51 185.32 305.51 197.72 322.1 197.72 322.1 240.62"/>
    <rect class="cls-1" x="359.56" y="185.32" width="13.27" height="55.3"/>
    <path class="cls-1" d="M411.66,241.72c15.88,0,28.76-12.09,28.76-28.75s-12.88-28.76-28.76-28.76S383,196.3,383,213A28.2,28.2,0,0,0,411.66,241.72Zm0-44.39c9,0,14.93,6.32,14.93,15.64s-5.92,15.64-14.93,15.64-14.85-6.4-14.85-15.64S402.74,197.33,411.66,197.33Z"/>
    <path class="cls-1" d="M463.91,228.37c0-12.08-.16-20.62-.08-20.62h.08c.08,0,7.5,10.51,12.56,17.46l11.29,15.41h12.49v-55.3H487V198c0,10,.16,19.83.08,19.83H487c-.07,0-8.53-11.93-12.08-16.67l-11.77-15.8H450.64v55.3h13.27Z"/>
    <path class="cls-1" d="M212.65,125c0,9.24,5.92,15.64,14.85,15.64s14.93-6.4,14.93-15.64-5.92-15.64-14.93-15.64S212.65,115.7,212.65,125Z"/>
    <path class="cls-1" d="M352.39,109.77v13.12H360c5.13,0,8.21-2.45,8.21-6.56s-3.08-6.56-8.21-6.56Z"/>
    <path class="cls-1" d="M125.85,43.87c0,9.25,5.92,15.65,14.85,15.65s14.93-6.4,14.93-15.65-5.92-15.64-14.93-15.64S125.85,34.55,125.85,43.87Z"/>
  </svg>
</template>

<script>
export default {
  name: "MonCompteFormation"
}
</script>