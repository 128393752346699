<template>
  <section>
    <h1>Comment développer des applications professionnelles pour vos clients avec Vue.js 3 (de A à Z)</h1>
    <h2>Le système complet pour apprendre Vue.js 3, accepter plus de missions et ENFIN augmenter votre TJM.</h2>
    <div class="avatar scaling">
      <VueIcon class="background"/>
      <VueIcon class="main"/>
    </div>
  </section>
</template>

<script>
import Badge from '../Badge.vue'
import VueIcon from '../icons/Vue.vue'
export default {
  name: 'Header',
  components: { Badge, VueIcon }
}
</script>

<style scoped>

section { 
  max-width: 750px;
  margin: 0 auto;
  padding: 60px 30px;
  text-align: center;
  box-sizing: border-box;
}

h1 {
  font-size: 2.5em;
  margin: 0 0 20px 0;
  line-height: 1.5;
}

h2 {
  font-size: 1.75em;
  margin: 40px 0 20px;
  line-height: 1.5;
  opacity: 0.75;
}

.avatar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
  margin: 50px auto 0;

  width: 300px;
  height: 300px;

	/* Subtle inner border */
	box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.015);
}

.avatar .main {
	height: 70%;
	width: 70%;
	z-index: 2;

	/* Optionally add a drop shadow to the main image	 */
	/* to make it feel "lifted"	 */
	filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.12));
}

.avatar .background {
	position: absolute;
	z-index: 1;
	pointer-events: none;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	transform: scale(0.9);
	filter: blur(13px) opacity(0.2);
}

.scaling{
  animation-name: scalex;
  animation-duration:3s;
  animation-iteration-count:infinite;
  animation-timing-function:ease-in-out;
}
    
@keyframes scalex{
  from {transform: scale(0.9);}
  65% {transform: scale(1.0);}
  to {transform: scale(0.9);}    
}

</style>