<template>
  <vs-button :active="true" flat class="badge" v-bind="$attrs"><slot>Badge</slot></vs-button>
</template>

<script>
export default {
  name: "Badge"
}
</script>

<style scoped>
a {
  color: #42b983;
}
.badge { 
  display: inline-block !important;
  cursor: default !important;
}
</style>