<template>
  <article class="element">
    <h4 data-accordion-element-trigger>
      <slot name="trigger"></slot>
    </h4>
    <div data-accordion-element-content class="content">
      <p>
        <slot name="content"></slot>
      </p>
    </div>
  </article>
</template>


<script>
export default {
  name: 'AccordionItem'
};
</script>

<style lang="scss" scoped>
</style>
