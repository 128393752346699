<template>
  <div class="main">

    <!-- AIDA -->
    <HeaderSection />
    <!-- Fin AIDA -->

    <section>

      <div class="content">
        <p>
          Si vous êtes ici, c’est que vous voulez apprendre à développer des applications avec Vue.js 3.
        </p>
        <p>
          Pourquoi ?
        </p>
        <p>
          <strong>Parce que vous avez compris que c’est le meilleur moyen pour :</strong>
          <ul>
            <li>Être à l’aise et confiant avec votre code (<em>et devenir un meilleur développeur…</em>)</li>
            <li>Accepter plus de missions (<em>et choisir celles qui vous intéressent…</em>)</li>
            <li>Augmenter votre TJM (<em>et vos revenus…</em>)</li>
          </ul>
        </p>
        <p>
          Le problème, c’est que ça peut paraître compliqué et chronophage.
        </p>
        <p>
          Ou peut-être que vous hésitez encore sur le framework que vous voulez apprendre (<em>Vue, React, Angular, Svelte…</em>).
        </p>
      </div>

      <div class="content">
        <p>
          <strong>Donc vous restez frustré…</strong>
        </p>
        <p>
          Frustré de quoi ?
        </p>
        <p>
          D'avoir appris le mauvais framework (<em>ou de l’avoir appris dans de mauvaises conditions…</em>)
        </p>
        <p>
          Et donc votre code n’est pas aussi professionnel que vous le souhaitez.
        </p>
      </div>

      <div class="content">
        <p>
          <strong>Mais ce n’est pas tout…</strong>
        </p>
        <p>
          Peut-être que vous êtes en <u>colère</u> à chaque fois que vous devez refuser une mission d’un client car vous n’avez pas appris le bon framework.
        </p>
        <p>
          Peut-être que vous êtes <u>inquiet</u> d’avoir des bugs avec votre framework (et de ne pas savoir les résoudre…)
        </p>
      </div>

      <div class="content">
        <p>
          <strong>Si c’est le cas, je vous comprends.</strong>
        </p>
        <p>
          Concrètement, le plus simple (<em>et le mieux</em>), c’est d'apprendre à développer avec Vue.js.
        </p>
        <p>
          Donc peut-être que pour vous en sortir, vous avez essayé de l’apprendre.
        </p>
        <p>
          Vous avez peut-être même déjà acheté une formation sur Vue.js.
        </p>
      </div>

      <div class="content">
        <p>
          <strong>Le problème ?</strong>
        </p>
        <p>
          C’est que vous n’avez pas suivi le contenu car il est trop ennuyant (<em>et qu’il manque d’un côté <u>pratique</u></em>).
        </p>
        <p>
          Ou alors, vous avez suivi le contenu mais il n’allait pas assez loin.
        </p>
        <p>
          Malheureusement, c’est le cas de beaucoup de formations sur Vue.js.
        </p>
      </div>

      <div class="content">
        <p>
          <strong>Ce qu’il vous faut ?</strong>
        </p>
        <h3><em>Une formation <u>complète</u>, tournée vers la <u>pratique</u>.</em></h3>
        <p>
          <strong>Cette formation ?</strong>
        </p>
        <p>
          Vous allez la suivre de A à Z.
        </p>
        <p>
          Elle vous apprendra TOUT sur Vue.js.
        </p>
        <p>
          Et surtout, vous saurez la mettre en pratique.
        </p>
      </div>

      <div class="content">
        <p>
          <strong>Une fois que vous l’aurez suivi ?</strong>
        </p>
        <p>
          Vous saurez <u>développer des applications professionnelles</u> de A à Z grâce à Vue.js 3.
        </p>
        <p>
          Vous pourrez donc <u>accepter plus de missions</u> (<em>ou alors, choisir celles qui vous intéressent…</em>)
        </p>
        <p>
          Et surtout, vous allez pouvoir <u>augmenter votre TJM</u> si vous êtes développeur freelance.
        </p>
      </div>

      <div class="content">
        <p>
          <strong>Le problème ?</strong>
        </p>
        <p>
          C’est que cette formation n’existe pas sur le marché francophone.
        </p>
        <p>
          Ou plutôt, elle <u>n’existait</u> pas.
        </p>
      </div>

      <div class="content">
        <p>
          <strong>Il y a quelques mois ?</strong>
        </p>
        <p>
          J’ai décidé de la créer.
        </p>
        <p>
          Et aujourd’hui, elle est <u>entièrement</u> disponible.
        </p>
      </div>

      <div class="content">
        <p>
          <strong>Concrètement ?</strong>
        </p>
        <p>
          C’est une formation vidéo tournée vers la pratique.
        </p>
        <p>
          Dedans vous retrouverez <u>5 sections</u>.
        </p>
      </div>

    </section>

    <PlanPart>
      <template #icon>
        <VueIcon />
      </template>
      <template #text>
        <h2>Les bases de Vue.js 3</h2>
        <h3>13 leçons pour 2h30 de vidéos</h3>
        <p>
          <ul>
            <li>Introduction à Vue.js</li>
            <li>Comment créer une application dynamique ?</li>
            <li>Comment utiliser les fonctionnalités essentielles du framework (<em>methods, events, computed, watchers, directives…</em>) ?</li>
            <li>Pourquoi et comment utiliser les components ?</li>
          </ul>
        </p>
      </template>
    </PlanPart>

    <PlanPart>
      <template #icon>
        <ViteIcon />
      </template>
      <template #text>
        <h2>Les outils à utiliser au quotidien</h2>
        <h3>12 leçons pour 3h13 de vidéos</h3>
        <p>
          <ul>
            <li>Vue CLI, Vite, Vue DevTools, Vue UI Librairies : Pourquoi ces outils existent-ils ?</li>
            <li>Comment se servir de ces outils pour développer une réelle application ?</li>
            <li>Que sont et comment bien se servir des Single File Components ? (<em>.vue</em>)</li>
          </ul>
        </p>
      </template>
    </PlanPart>

    <PlanPart>
      <template #icon>
        <VueRouterIcon />
      </template>
      <template #text>
        <h2>Le routing via VueRouter</h2>
        <h3>11 leçons pour 2h14 de vidéos</h3>
        <p>
          <ul>
            <li>Introduction à VueRouter.</li>
            <li>Comment créer et gérer des pages à volonté (<em>Navigation, paramètres, pages 404, nested routes, guards…</em>) ?</li>
          </ul>
        </p>
      </template>
    </PlanPart>

    <PlanPart>
      <template #icon>
        <VuexIcon />
      </template>
      <template #text>
        <h2>Le state via Vuex</h2>
        <h3>10 leçons pour 2h44 de vidéos</h3>
        <p>
          <ul>
            <li>Introduction à Vuex.</li>
            <li>Comment créer un state pour simplifier son code ?</li>
            <li>Comment accéder aux actions et aux valeurs depuis n’importe quelle partie de son code ?</li>
          </ul>
        </p>
      </template>
    </PlanPart>

    <PlanPart>
      <template #icon>
        <AdvancedVueIcon />
      </template>
      <template #text>
        <h2>Le développement avancé</h2>
        <h3>11 leçons pour 2h07</h3>
        <p>
          <ul>
            <li>Pourquoi et comment utiliser la Composition API ?</li>
            <li>Comment créer des composables ? (<em>reusable code</em>)</li>
            <li>Quel est l'utilisation avancée des slots ? (<em>named slots, slot prop, …</em>)</li>
            <li>Comment gérer les cas plus rares grâce à des notions plus avancés ?</li>
          </ul>
        </p>
      </template>
    </PlanPart>

    <section>
      <div class="containerMoreBtn">
        <vs-button
          border
          size="large"
          href="https://dynalist.io/d/h3g6sttX3PI07Pc1TBzv-BVm#theme=dark"
          blank>
          Voir le plan détaillé de toute la formation
        </vs-button>
      </div>
    </section>

    <section>

      <div class="content">
        <p>
          <strong>Vous vous demandez peut-être où est ce qu’est l’aspect pratique là-dedans ?</strong>
        </p>
        <p>
          Et bien je vais vous le dire…
        </p>
        <p>
          Du <u>début à la fin</u>.
        </p>
      </div>

      <div class="content">
        <p>
          Pourquoi ?
        </p>
        <p>
          <strong>Parce que tout au long de la formation je réalise un projet Vue.js.</strong>
        </p>
        <p>
          Ce projet ? <strong><em>VueTracker</em></strong> (Vue.js 3 + API REST pour des données permanentes)
        </p>
        <p>
          <img src="/launch_app.min.gif" alt="GIF du lancement de l'application web que l'on va créé" width="100%">
        </p>
        
        <p>
          <strong>Le code du projet est versionné sur GitHub, voici d'ailleurs toutes les branches sur cette image.</strong>
        </p>
      </div>

    </section>
      
    <section class="specialTwoCols">
      <div class="containerImg">
        <img src="/vuetracker_branches.min.png" alt="Screenshot de toutes les branches du projet GitHub">
      </div>
      <div class="centerElements">
      
        <div>
          <div>
            <p>
              Vous pourrez <u>accéder au code du projet</u> à n’importe quel moment de la formation !
            </p>
            <p>
              <strong>Vous n’aurez pas besoin de tout développer de votre côté.</strong>
            </p>
            <p>
              Car chaque section contient ses propres branches pour avoir le code de départ et de fin.
            </p>
          </div>
          
          <div style="margin-top: 60px;">
            <p>
              <strong>À la fin de chaque section ?</strong>
            </p>
            <p>
              Je vous donne un challenge pour que vous puissiez utiliser Vue.js.
            </p>
            <p>
              Une branche du GitHub est d'ailleurs réservé à chacun des challenges. 
            </p>
          </div>
        </div>

      </div>
    </section>

    <section>

      <div class="content">
        <p>
          <strong>Pour résumer ?</strong>
        </p>
        <p>
          Cette formation est à la fois la plus complète en matière de contenu sur Vue.js 3.
        </p>
        <p>
          Mais c’est surtout la plus tournée vers la pratique (<em>sur le marché francophone…</em>)
        </p>
      </div>
      
      <div class="content">
        <p>
          <strong>Pour finir, voici une lesson concrète tirée de la 2ème section de cette formation complète sur Vue.js 3 :</strong>
        </p>
        <p class="containerIframe">
          <iframe src="https://player.vimeo.com/video/523186578" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </p>
      </div>

      <vs-row justify="center">
        <h3>Ce que disent les participants de cette formation</h3>
        <Testimonial
          :sentences="[
            '<strong>Les explications sont claires, il n’y a pas de blabla comme il peut y en avoir dans d’autres formations, ici on va à l’essentielle et c’est très bien.</strong>'
          ]"
          author="Cyril"
          :completionPercent="50"
        />
        <Testimonial
          :sentences="[
            '<strong>À la fin de la formation nous sommes capable de faire une application professionnelle « from scratch »</strong>'
          ]"
          author="Charles"
          :completionPercent="100"
        />
        <Testimonial
          :sentences="[
            '<strong>Un développeur junior sera autonome car la formation est orientée pratique. [...]<br/>Un sénior comprendra pourquoi et comment Vue.js 3 permet de gagner beaucoup de temps de développement.</strong>'
          ]"
          author="Charles"
          :completionPercent="100"
        />
      </vs-row>
      
    </section>

    <PricingSection />    

    <PlanPart>
      <template #icon>
        <Vuelidate />
      </template>
      <template #text>
        <h2>Gestion complète de formulaires</h2>
        <h3><Badge>Bonus</Badge> 8 leçons pour 1h45 de vidéos</h3>
        <p>
          <ul>
            <li>Quelle librairie choisir pour valider nos données ?</li>
            <li>Comment utiliser Vuelidate pour Vue 3 ?</li>
            <li>De quelles manières créer nos form-components ? (<em>BaseInput.vue, …</em>) </li>
            <li>Comment gérer l'accessibilité de nos formulaires ? (<em>fieldset, aria, …</em>)</li>
          </ul>
        </p>
      </template>
    </PlanPart>

    <PlanPart>
      <template #icon>
        <UsersVue />
      </template>
      <template #text>
        <h2>Système d'utilisateurs via Firebase</h2>
        <h3><Badge>Bonus</Badge> 9 leçons pour 1h16 de vidéos</h3>
        <p>
          <ul>
            <li>Comment créer un système d'inscription / connexion ?</li>
            <li>De quelle manière intégrer Firebase sur Vue.js ?</li>
            <li>Comment gérer un layout selon les utilisateurs connectés ou non ?</li>
          </ul>
        </p>
      </template>
    </PlanPart>

    <!-- Garantie -->
    <section>
      <div class="content">
        <p>
          <strong>Concernant la garantie de cette formation.</strong>
        </p>
        <p>
          Vous avez <u>30 jours</u> pour <strong>tester la formation</strong>, PUIS décidez si vous voulez la garder.
        </p>
        <p>
          Concrètement, après votre commande, vous aurez 30 jours pour consulter la formation et voir si elle correspond à vos attentes.
        </p>
        <p>
          <strong>Si vous n’êtes pas satisfait ?</strong> Il vous suffira de m’envoyer un email à contact@devtheory.fr et je vous rembourserai <u>jusqu’au dernier centime</u> (<em>quelle que soit votre raison</em>).
        </p>
      </div>
    </section>
    <!-- Fin Garantie -->

    <section>
      <div>    
        <h3 style="margin-bottom: 0; margin-top: 100px;">Vous avez d'autres questions ?</h3>  
        
        <!-- FAQ -->
        <Accordion>

          <AccordionItem>
            <template slot="trigger">
              Comment se passe la commande ?
            </template>
            <template slot="content">
              <ol>
                <li>Choisissez la version qui vous convient.</li>
                <li>Remplissez le formulaire de paiement.</li>
                <li>
                  Vous allez automatiquement recevoir un email avec un lien et vos identifiants de connexion.
                  <br/>
                  Si vous avez pris la version "Équipe" vous recevrez alors un lien pour pouvoir indiquer les emails des comptes à créer.
                </li>
                <li>Les comptes créés auront un accès à vie à la formation (hors remboursement).</li>
              </ol>
            </template>
          </AccordionItem>

          <AccordionItem>
            <template slot="trigger">
              Je n'ai jamais développé avec Vue.js
            </template>
            <template slot="content">
              Parfait, dans cette formation vous allez voir toutes les bases de Vue JS (même si vous partez de zéro).
            </template>
          </AccordionItem>

          <AccordionItem>
            <template slot="trigger">
              Je ne connais pas le JavaScript
            </template>
            <template slot="content">
              Si vous n’êtes pas déjà développeur JavaScript (front-end ou back-end), cette formation n’est pas faite pour vous.
              <br/>
              Par contre, si vous êtes "au moins développeur JS junior”, elle vous conviendra.
            </template>
          </AccordionItem>

          <AccordionItem>
            <template slot="trigger">
              Je développe déjà avec Vue 2
            </template>
            <template slot="content">
              Vue 2 et Vue 3 n'ont pas énormément de différences pour un développeur junior, si c'est votre cas alors seulement la 1ère et 2ème section vous seront familières
              <br/>
              Tout le reste vous sera utile: surtout la section "Développement avancé" où nous parlerons des nouvelles APIs avec Vue 3, et bien sûr toutes les sections bonus.
            </template>
          </AccordionItem>

          <AccordionItem>
            <template slot="trigger">
              Je développe déjà avec React, Angular, ou autres
            </template>
            <template slot="content">
              Alors oui, apprendre un autre framework n’est pas <u>indispensable</u>.<br />
              Par contre ?<br />
              Si vous avez envie de tester Vue.js ?<br />
              Si vous voulez ajouter une corde à votre arc ?<br />
              Et si vous voulez comparer par vous-même votre framework et Vue (pour voir celui que vous préférez) ?<br />
              Cette formation vous donnera toutes les clés dont vous avez besoin pour devenir un meilleur développeur et étoffer vos compétences…
            </template>
          </AccordionItem>

          <AccordionItem>
            <template slot="trigger">
              J’ai déjà commandé la formation, comment puis-je y accéder ?
            </template>
            <template slot="content">
              À votre inscription, vous avez reçu un email pour paramétrer votre compte “élève”.
              <br/>
              Si vous l’avez déjà paramétré, vous avez juste à vous connecter à votre compte sur la plateforme d’apprentissage de DevTheory.
              <br/>
              <a href="https://devtheory.schoolmaker.co/login" target="_blank" rel="noopener">Cliquez ici pour accéder à cette plateforme.</a>.
            </template>
          </AccordionItem>

        </Accordion>
      </div>
      <!-- Fin FAQ -->
      <!-- Contact -->
      <div>
        <p>Pour toutes autres choses, <strong>contactez-moi sur WhatsApp ou par mail</strong> :</p>
        <ul class="littlePlan">
          <li>
            <WhatsAppIcon class="icon"/>
            <a href="https://wa.me/33780997571" target="_blank" rel="noopener">Mon numéro de téléphone</a>
          </li>
          <li>
            <MailIcon class="icon"/>
            <a href="mailto:contact@devtheory.fr">Mail à contact@devtheory.fr</a> 
          </li>
        </ul>
      </div>
      <!-- Fin Contact -->
    </section>

  </div>
</template>

<script>
import HeaderSection from './components/sections/Header.vue'
import PricingSection from './components/sections/Pricing.vue'

import PlanPart from './components/PlanPart.vue'
import Accordion from "./components/Accordion.vue";
import AccordionItem from "./components/AccordionItem.vue";
import Badge from "./components/Badge.vue";
import Testimonial from "./components/Testimonial.vue";

import VueIcon from './components/icons/Vue.vue'
import ViteIcon from './components/icons/Vite.vue'
import VueRouterIcon from './components/icons/VueRouter.vue'
import VuexIcon from './components/icons/Vuex.vue'
import AdvancedVueIcon from './components/icons/AdvancedVue.vue'
import Vuelidate from './components/icons/Vuelidate.vue'
import UsersVue from './components/icons/UsersVue.vue'
import WhatsAppIcon from './components/icons/WhatsApp.vue'
import MailIcon from './components/icons/Mail.vue'

export default {
  components: { 
    HeaderSection,
    PricingSection,
    PlanPart,
    Accordion,
    AccordionItem,
    Badge,
    VueIcon,
    ViteIcon,
    VueRouterIcon,
    VuexIcon,
    AdvancedVueIcon,
    Vuelidate,
    UsersVue,
    WhatsAppIcon,
    MailIcon,
    Testimonial
  },
  data() {
    return {
      scrollTrigger: null
    }
  },
  methods: {
    animateFrom(elem, direction) {
      direction = direction | 1;
      
      var x = 0,
          y = direction * 100;
      if(elem.classList.contains("gs_reveal_fromLeft")) {
        x = -100;
        y = 0;
      } else if(elem.classList.contains("gs_reveal_fromRight")) {
        x = 100;
        y = 0;
      }
      gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        duration: 1.25, 
        x: 0,
        y: 0, 
        autoAlpha: 1, 
        ease: "expo", 
        overwrite: "auto"
      });
    },
    hide(elem) {
      gsap.set(elem, {autoAlpha: 0});
    }
  },
  created() {     
    this.$vs.setColor('dark', '#fff')
    this.$vs.setColor('background-contrast', '#1c2233')
    this.$vs.setColor('background', '#283149')
  },
  mounted() {
    // GSAP animations
    gsap.registerPlugin(ScrollTrigger);  
    gsap.utils.toArray(".iconPlanPart svg").forEach((elem) => {
      this.hide(elem);      
      ScrollTrigger.create({
        trigger: elem,
        onEnter: () => { this.animateFrom(elem) }, 
        onEnterBack: () => { this.animateFrom(elem, -1) },
        onLeave: () => { this.hide(elem) }
      });
    });
  }
}
</script>

<style scoped lang="scss">

.main {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(var(--vs-dark));
}

section {
  width: 90%;
  max-width: 750px;
  margin: 0 auto;
  &.marginTop { margin-top: 50px; }
  p {
    font-size: 20px;
    line-height: 1.75;
    margin: 0 0 1.5em 0;
  }
  .subLine {
    position: relative;
    top: -10px;
    font-size: 16px;
    opacity: 0.75;
  }
  h3 {
    margin: 50px 0;
    font-size: 30px;
    line-height: 1.5;
    font-weight: normal;
    text-align: center;
  }
  .content {
    margin: 60px 0;
  }
}

.specialTwoCols {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  max-width: 900px !important;
  img {
    width: auto;
    max-height: 600px;
    margin-right: 40px;
  }
}

.centerElements {
  display: flex;
  justify-content: center;
  place-items: center;
}

.mainIcon {
  display: block;
  margin: 10% auto;
  width: 50%;
  fill: rgb(var(--vs-dark));
  opacity: 0.7;
}

ul {
  font-size: 20px;
  li {
    margin: 20px 0;
  }
}

.containerIframe {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.littlePlan {
  margin: 7.5% auto;
  list-style: none;
  font-size: 20px;
  li {
    margin: 35px 0;
  }
  .icon {
    display: inline-block;
    width: 50px;
    margin-right: 15px;
    vertical-align: middle;
  }
}

.containerMoreBtn {
  margin: 40px auto 0;
  text-align: center;
  button {
    display: inline-block
  }
}

@media (max-width: 800px) {
  .specialTwoCols {
    flex-direction: column;
    .containerImg {
      text-align: center;
      margin-bottom: 60px;
    }
  }
}

@media (max-width: 450px) {
  .littlePlan {
    padding-left: 0;
  }
}

</style>

<style>
body {
  margin: 0;
  background-color: rgb(var(--vs-background))
}
a {
  color: rgb(var(--vs-dark));
}
/* @terwanerik/scrolltrigger */
.visible, .invisible {
  opacity: 0.0;
  transform: translateY(20px);
  transition: 0.5s ease;
}
.visible {
  opacity: 1.0;
  transform: translateY(0);
}
/* Vuesax */
.vs-notification {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}
.vs-dialog-content {
  background: rgba(0, 0, 0, 0.5) !important;
}
</style>