import Vue from 'vue'
import App from './App.vue'

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
Vue.use(Vuesax, {
  colors: {
    primary: '#F73859',
    success: 'rgb(23, 201, 100)',
    danger: '#dc3545',
    warning: 'rgb(255, 130, 0)',
    dark: '#283149'
  }
})

Vue.config.productionTip = false

new Vue({
  render: function (h) { return h(App) },
}).$mount('#app')
