<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 729.569 629.254">
    <g id="vue">
      <path id="Tracé_1" data-name="Tracé 1" d="M583.655,0H729.569L364.785,629.253,0,0H279.06l85.724,145.914L448.685,0Z" fill="#41b883"/>
      <path id="Tracé_2" data-name="Tracé 2" d="M0,0,364.785,629.253,729.569,0H583.655L364.785,377.552,144.09,0Z" fill="#41b883"/>
      <path id="Tracé_3" data-name="Tracé 3" d="M50.56,0,271.255,379.376,490.125,0H355.155l-83.9,145.914L185.53,0Z" transform="translate(93.53)" fill="#35495e"/>
    </g>
    <path id="Tracé_13" data-name="Tracé 13" d="M172.211,45.8A42.8,42.8,0,1,1,129.408,3,42.8,42.8,0,0,1,172.211,45.8Zm71.338,28.535A28.535,28.535,0,1,1,215.014,45.8,28.535,28.535,0,0,1,243.549,74.338Zm-57.07,99.873a57.07,57.07,0,1,0-114.141,0v42.8H186.479ZM72.338,74.338A28.535,28.535,0,1,1,43.8,45.8,28.535,28.535,0,0,1,72.338,74.338ZM215.014,217.014v-42.8a85.206,85.206,0,0,0-10.7-41.462,42.874,42.874,0,0,1,53.5,41.462v42.8ZM54.5,132.749a85.22,85.22,0,0,0-10.7,41.462v42.8H1v-42.8a42.8,42.8,0,0,1,53.5-41.462Z" transform="translate(471.752 412.24)" fill="#41b883"/>
  </svg>
</template>

<script>
export default {
  name: "UsersVue"
}
</script>