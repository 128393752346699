<template>  
  <form @submit.prevent>
    <div v-if="!isSuccess">
      <div class="containerTwoInputs">
        <div>
          <label for="firstname">Prénom</label>
          <input ref="firstname" id="firstname" type="text" placeholder="John" :disabled="isLoading">             
        </div>
        <div>
          <label for="lastname">Nom</label>
          <input ref="lastname" id="lastname" type="text" placeholder="Doe" :disabled="isLoading">           
        </div>
      </div>
      <div class="containerTwoInputs">
        <div>
          <label for="email">Adresse email</label>
          <input ref="email" id="email" type="email" placeholder="john@doe.com" :disabled="isLoading">              
        </div>
        <div>
          <label for="birthday">Date de naissance</label>
          <input ref="birthday" id="birthday" type="date" placeholder="01/01/1990" @click.once="$event.target.setAttribute('type', 'date')" :disabled="isLoading">              
        </div>
      </div>
      <strong v-show="error">Erreur: {{ error }}</strong>
      <vs-button @click="submitForm" blank size="large" :loading="isLoading">
        Faire financer ma formation
      </vs-button>
    </div>
    <strong v-else>Succès ! Votre demande de financement est en préparation 🔥</strong>
  </form>
</template>

<script>
import axios from 'axios'
export default {
  emits: ['submitAndConfirmed'],
  data() {
    return {
      isLoading: false,
      error: null,
      isSuccess: false
    }
  },
  methods: {
    async submitForm() {
      this.isLoading = true
      this.error = null
      try {
        let apiURL = 'https://api.devtheory.fr'
        const response = await axios.post(apiURL + '/v1/cpf/demand_vue', {
          firstname: this.$refs.firstname.value,
          lastname: this.$refs.lastname.value,
          email: this.$refs.email.value,
          birthday: this.$refs.birthday.value.split('-').reverse().join('/'),
        });
        if (response.data.status === 'error') {
          this.error = response.data.message
        } else {
          this.isSuccess = true
          this.$emit('submitAndConfirmed')
        }
      } catch (e) {
        console.error(e)
        this.error = 'Une erreur inconnue est arrivé ! Si cela se reproduit alors envoyez-moi ces informations par mail à l\'adresse contact@devtheory.fr'
      }      
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>

form {
  padding: 0 2%;
}

input {
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  &.email { width: 100%; }
}

label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-size: 14px;
  color: rgb(var(--vs-dark));  
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.containerTwoInputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  > div {
    width: 47.5%
  }
}

.vs-button {
  display: block;
  margin: 20px auto 0;
  padding: 7px 15px;
}

strong {
  display: inline-block;
  width: 100%;
  margin: 20px 0 0;
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(var(--vs-dark));
}
</style>